<template>
  <div id="bubbleDialog-perspective" @click="$emit('handleClick',$event)" :style="{
       display,
       left:left+'px',
       top:top+'px',
  }" >
  <div id="bubbleDialog-wrapper"
       :style="{
       transform:`translateZ(${zPx}px) scale(${scale}) rotateX(${xDeg}deg) rotateY(${yDeg}deg)`,
       transformOrigin
      }"
       ref="wrapper">
      <slot>
        Loading...
      </slot>
      </div>
  </div>
</template>
<script>
import anime from "../../assets/js/anime.es";
export default {
  data(){
    return{
        top:0,
        left:0,
        scale:0,
        display:'none',
        pokeAnime:null,
        createAnime:null,
        xDeg:0,
        yDeg:0,
        transformOrigin: '50% 50% 0',
        zPx:0,
    }
  },
  props:[],
  methods:{
    decorateBubbleDialog(externalCb){
      externalCb(this.$refs.wrapper);
    },
    moveBubble(position){
      this.pokeAnime?.pause();
      this.createAnime?.pause();
      let that = this;
      anime({
        targets:that,
        top:position.top,
        left:position.left,
        scale:1
      })
    },
    createBubble(position){
      this.top = position.top;
      this.left = position.left;
      this.pokeAnime?.pause();
      this.createAnime = anime({
        targets: this,
        scale:1,
      })
    },
    pokeBubble(){
      this.createAnime?.pause();
      this.pokeAnime = anime({
        targets: this,
        scale:0,
        changeComplete: this.hidBubbleDialog,
        easing:'easeInOutQuart'
      })
    },
    calculateDirectionCoEfficients(force,width,height,x,y){
      const y_1 = height/width * x;
      const y_2 = -height/width * x + height;

      const pointToCenter = Math.sqrt((width/2 - x)**2 + (height/2 - y)**2);
      const horizontalToCenter = Math.abs(width/2 - x);
      const verticalToCenter = Math.abs(height/2 - y);
      let inclineLineSize;
      let zDirectionRatio;

      if(
          (y_1 <= y && y <= y_2)
      ||  (y_1 >= y && y >= y_2)
      ){
        if(verticalToCenter === 0){
          zDirectionRatio = 1 - (horizontalToCenter / (width/2));
        }else{
          inclineLineSize = pointToCenter / (horizontalToCenter/(width/2));
          zDirectionRatio = 1 - (pointToCenter / inclineLineSize);
        }

      }else{
        if(horizontalToCenter === 0){
          zDirectionRatio = 1 - (verticalToCenter / (height/2));
        }else{
          inclineLineSize = pointToCenter / (verticalToCenter/(height/2));
          zDirectionRatio = 1 - (pointToCenter / inclineLineSize);
        }
      }
      let zForce = -zDirectionRatio * force;
      let xyForce = (1 - zDirectionRatio) * force;
      let yRatio = horizontalToCenter / (width/2);
      let xRatio = verticalToCenter / (height/2);
      let xyRatioAggregate = yRatio+ xRatio
      let xForce = xyForce * (xRatio /xyRatioAggregate);
      xForce = y > height/2 ? -xForce : xForce;
      let yForce = xyForce * (yRatio /xyRatioAggregate);
      yForce = x > width/2 ? yForce : -yForce;

      return {xForce,yForce,zForce};
    },
    flingBubble(e){
      let offsetX = e.offsetX;
      let offsetY = e.offsetY;
      let {width:contentWidth,height:contentHeight} = e.target.getBoundingClientRect();
      // let originX = (offsetX/contentWidth) * 100;
      // let originY = (offsetY/contentHeight) * 100
      let {xForce,yForce,zForce} = this.calculateDirectionCoEfficients(90,contentWidth,contentHeight,offsetX,offsetY);
      anime({
        targets: this,
        xDeg:[
          { value: xForce, duration: 300, delay: 0,easing:'easeInOutQuart' },
          { value: 0, duration: 200, delay: 0,easing: 'spring(2, 50, 10, 10)'} //'' easeOutElastic(5, 3)
        ],
        yDeg:[
          { value: yForce, duration: 300, delay: 0,easing:'easeInOutQuart' },
          { value: 0, duration: 200, delay: 0,easing: 'spring(2, 50, 10, 10)'} //'' easeOutElastic(5, 3)
        ],
        zPx:[
        { value:  zForce, duration: 300, delay: 0,easing:'easeInOutQuart' },
        { value: 0, duration: 200, delay: 0,easing: 'spring(2, 50, 10, 10)'} //'' easeOutElastic(5, 3)
        ],
        loop:false
      })
    },
    showBubbleDialog(){
      this.display = 'block';
    },
    hidBubbleDialog(){
      this.display = 'none';
    },

    mounted(){

    }
  }
}
</script>
<style scoped>
#bubbleDialog-perspective{
  position: absolute;
  perspective: 200px;
  /*perspective-origin:center top;*/
  transform: translateX(-50%);
}
#bubbleDialog-wrapper{
  border: 1px solid #999;
  box-sizing: border-box;
  border-radius: 5px;
  /*background-image: linear-gradient(45deg,#00bcd4, #fa1a09);*/
  /*background-size: 100% 100%;*/
  /*background-position: -200px 0%;*/
  /*background-repeat: no-repeat;*/
  /*transition: .5s background-position ease-out;*/
}
#bubbleDialog-wrapper:hover{
  /*background-position: 0% 0%;*/
}
#bubbleDialog-wrapper:before{
  content: '';
  width: 0;
  height: 0;

  border-bottom: 5px solid #999;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
#bubbleDialog-wrapper:after{
  content: '';
  width: 0;
  height: 0;
  border-bottom: 5px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
</style>
